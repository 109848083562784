/* Footer.css */
.footer {
    background-color: #000000;
    color: #ffffff;
    margin-top: -2px;
}

.footer-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
    max-width: 90vw;
    margin: 0 auto;
    text-align: center;
    padding-bottom: 15.625vw;
    align-items: center;
}

.footer-logo {
    grid-column: span 1;
    justify-self: center;
}

.footer-logo-img {
    width: 40%;
    margin-bottom: 1rem;
}


.footer-section h3 {
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 1rem;
}

.footer-links {
    line-height: 0rem;
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.footer-links {
    padding-top: 10px;
}

.footer-links li {
    padding: 0.5rem 0;
    cursor: pointer;
}

.footer-link {
    color: #9DAFB0;
    text-decoration: none;
    transition: color 0.3s;
    display: inline-block;
}

.footer-link:hover {
    color: #d1d5db;
    /* hover:text-gray-300 */
}

.footer-link:hover {
    color: #d1d5db;
    /* hover:text-gray-300 */
}

/* Footer bottom section */
.footer-bottom {
    background-color: #c52470;
    /* bg-pink-600 */
    color: #000000;
    /* text-black-900 */
    text-align: center;
    padding: 1rem 0;
    font-size: 2.5vw;
}



@media (min-width: 1024px) {

    _::-webkit-full-page-media,
    _:future,
    :root {
        .footer-bottom {
            font-size: 1rem;
            z-index: 200;
            color: #ffffff;
        }

        .footer-bottom p {
            height: 1rem;
        }

        .footer-container h3 {
            height: 1rem;
        }

        .footer-container a {
            height: 1rem;
        }


    }


    .footer-container {
        grid-template-columns: repeat(4, 1fr);
        text-align: left;
        padding-top: 4.5vw;
        padding-bottom: 4.5vw;
        text-align: left;
    }


    .footer-logo {
        grid-column: span 1;
        justify-self: start;
        align-self: center;
        margin-right: 15vw;
    }

    .footer-logo-img {
        margin-left: auto;
        margin-right: auto;
        width: 15vw;
    }

    .footer-bottom {
        font-size: 0.668vw;
    }
}

@media (max-width: 768px) {


    .footer-container {
        padding-top: 25px;
        gap: 0rem;
    }

    .footer-container h3 {
        height: 1rem;
    }

    .footer-bottom p {
        height: 1rem;
    }

    .footer-links a {
        height: 1rem;
        margin-top: -1rem;
    }

    .footer-bottom {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.5rem 0.5rem;

    }

    .footer-bottom p {
        color: #ffffff;
        height: 1rem;
        font-size: 1rem;
        margin-bottom: 0em;
    }
}