.about-App {
    background-color: rgba(0, 0, 0, 0.3);
    /* Semi-transparent black overlay */
    color: white;
    font-family: 'GothamBook';
    width: 100%;
    box-sizing: border-box;
    margin: 0 auto;
    max-width: 100vw;
    overflow-x: hidden;
    position: relative;
    /* For proper stacking context */
    min-height: 100vh;
    isolation: isolate;
}

/* Add a pseudo-element for the fixed background */
.about-App::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../assets/DoddBG1.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -1;
    /* Place behind content */
}

/* Update about-content to ensure proper layering */
.about-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8rem 2rem;
    padding-bottom: 0rem;
    position: relative;
    /* For proper stacking */
    z-index: 1;
    /* Place above background */
    background-color: rgba(0, 0, 0, 0.1);
    /* Additional overlay for better text readability */
    width: 100%;
    box-sizing: border-box;
}

/* First row layout for image and text */
.about-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 90%;
    width: 90vw;
    margin-bottom: 2rem;
    gap: 5rem;
    flex-wrap: wrap;
}

/* Styling for the image */
.about-image {
    width: 25%;
    max-width: 40%;
    padding: 1rem;
}

.about-image img {
    width: 100%;
    height: auto;
    border-radius: 8px;
}

/* Styling for the text beside the image */
.about-text {
    font-family: 'nyghtShift';
    color: #c52470;
    flex: 1;
    font-size: clamp(1.8rem, 2.5rem, 3.3rem);
    line-height: 1.6;
}

/* Full-width text block */
.about-full-text {
    flex-direction: column;
    max-width: 90%;
    width: 90vw;
    text-align: left;
    gap: 0px;
    align-items: flex-start;
    /* Aligns contents to the left */
    text-align: left;
    /* Ensures text within the container aligns left */
}

.about-full-text h2 {
    font-family: 'nyghtShift';
    text-align: left;
    letter-spacing: 2px;
    font-size: 3rem;
    margin-bottom: -1vh;
}

.about-full-text p {
    margin-bottom: 0vh;
    font-size: 1.7rem;
    line-height: 1.5;
}

/* Responsive Design */
@media (max-width: 1024px) {
    .about-row {
        gap: 2rem;
    }

    .about-image {
        width: 90%;
    }

    .about-text {
        font-size: clamp(1.6rem, 2rem, 2.5rem);
    }
}


@media (max-width: 768px) {
    .about-content {
        padding: 3rem 1rem;
        margin-top: 20%;
    }

    .about-row {
        flex-direction: column;
        align-items: center;
        gap: 1.5rem;
        /* Reduce spacing for mobile layout */
    }

    .about-text {
        font-size: 1.75rem;
        line-height: 1.4;
        text-align: center;
        /* Ensure text is centered */
    }

    .about-full-text h2 {
        text-align: center;
        font-size: 1.75rem;
    }

    .about-full-text p {
        font-size: 1rem;
        line-height: 1.5;
        text-align: left;
    }

    .about-image {
        max-width: 100%;
        /* Allow image to span full width */
        padding: 0;
    }

    .about-image img {
        border-radius: 6px;
    }
}