/* @font-face {
  font-family: 'GothamLight';
  src: url('../assets/fonts/gotham/GothamLight.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'GothamThin';
  src: url('../assets/fonts/gotham/Gotham-Thin.otf') format('opentype');
  font-weight: normal;
  font-style: normal;

} */

body {
  font-family: 'GothamBook', sans-serif;
}

.App {
  text-align: center;
  max-height: 99vh;
}

.main-container {
  justify-content: center;
  align-items: center;
  min-height: 95vh;
  display: flex;
}

@media (max-width: 768px) {

  #smooth-wrapper {
    position: static !important;
    /* Allow scrolling on mobile */
  }

  .main-container {
    min-height: auto;
  }
}