.carousel-wrapper {
    height: auto;
    position: relative;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    padding-bottom: 1rem;
    min-height: 400px;
    /* Adjust based on your content */
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    will-change: transform;
    contain: content;
}

.carousel-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    align-items: center;
    gap: 1rem;
}

.grid-Row {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
    padding: 10px 10px;
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    min-height: 400px;
    /* Add minimum height */
    width: 100%;
    /* Force full width */
}

.grid-column {
    flex: 1 1 calc(33.33% - 20px);
    max-width: calc(33.33% - 20px);
    box-sizing: border-box;
}

/* Container for each image */
.project-image-wrapper {
    position: relative;
    overflow: visible;
    max-width: 100%;
    border-radius: 8px;
    aspect-ratio: 4/3;
    height: 25%;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    /* will-change: transform; */
    scale: 0.9;
}

.project-image-wrapper:hover {
    .project-overlay {
        opacity: 1;
        transform: translateY(0);
        transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    }

    /* transform: scale(1.05); */
    box-shadow: 10px 10px 10px rgba(77, 14, 44, 0.8);
}

/* Style for the image */
.project-image {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    will-change: transform;
    object-fit: cover;
    /* Optimize performance */
}

/* Overlay container for title and subtitle */
.project-overlay {
    position: absolute;
    z-index: 10;
    opacity: 1;
    bottom: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    padding: 0.5rem;
    background: linear-gradient(transparent, rgba(0, 0, 0, 1));
    border-radius: 8px;
    color: #fff;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    justify-content: flex-end;
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    overflow: hidden;

}

.project-title {

    font-size: 2.25rem;
    letter-spacing: 1.25px;
    font-family: 'nyghtShift';
    height: auto;
    margin: 0px 15px;
    transition: transform 0.5s ease-in-out;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.project-subtitle {
    font-family: 'GothamBook';
    font-size: 1rem;
    opacity: 0.8;
    margin: 0px 15px;
    transition: transform 0.5s ease-in-out;
    word-wrap: break-word;
    overflow-wrap: break-word;
    max-width: 100%;
    line-height: 1.4;
    margin-top: 0.5rem;
}

_::-webkit-full-page-media,
_:future,
:root {
    .project-overlay {
        transform: translate3d(0, 0, 0);
        -webkit-transform: translate3d(0, 0, 0);
    }
}


@media screen and (max-width: 1024px) {
    .grid-column {
        flex: 1 1 calc(50% - 20px);
        max-width: calc(50% - 20px);
    }
}

/* Responsive styling for iPad/tablet screens */
@media screen and (min-width: 768px) and (max-width: 1024px) {

    .carousel-wrapper {
        min-height: 600px;
        /* Increased for tablets */
        height: auto !important;
        overflow: visible !important;
        contain: layout style paint;
    }

    .grid-Row {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1rem;
        padding: 10px;
        min-height: 600px !important;
        width: 100% !important;
        opacity: 1 !important;
        visibility: visible !important;
        transform: none !important;
    }

    .grid-column {
        flex: 1 1 100%;
        max-width: 100%;
        flex: 1 1 100%;
        max-width: 100%;
        min-height: 300px;
        /* Force minimum height */
        display: block !important;
        visibility: visible !important;
    }

    .project-image-wrapper {
        aspect-ratio: 4/3;
        height: auto;
        margin: 0 auto;
        min-height: 200px;
        /* Force minimum height */
        display: block !important;
        visibility: visible !important;
    }

    .project-title {
        margin-left: 0px;
        font-size: 3vw;
        font-weight: 500;
    }

    .project-subtitle {
        margin-left: 0px;
        font-size: 0.8rem;
        font-family: Arial, Helvetica, sans-serif;
    }

    .carousel-container {
        gap: 0.5rem;
    }

    .project-overlay {
        opacity: 1;
    }

    .project-image-wrapper:hover {
        transform: none !important;
        box-shadow: none !important;
    }

    .project-title,
    .project-subtitle {
        transform: none !important;
        transition: none !important;
    }
}

@media screen and (max-width: 768px) {


    .grid-Row {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1rem;
        padding: 10px;
    }

    .grid-column {
        flex: 1 1 100%;
        max-width: 100%;
    }

    #comlumn3 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
        padding: 10px;
    }

    #comlumn3 .project-image-wrapper {
        width: 100%;
        height: auto;
        box-sizing: border-box;
    }

    .project-image-wrapper {
        aspect-ratio: 4/3;
        height: auto;
        margin: 0 auto;
    }

    .project-title {
        margin-left: 0px;
        font-size: 4vw;
        font-weight: 500;
    }

    .project-subtitle {
        margin-left: 0px;
        font-size: 0.6rem;
        font-family: Arial, Helvetica, sans-serif;
    }

    .carousel-container {
        gap: 0.5rem;
    }

    .project-overlay {
        opacity: 1;
    }

    /* .project-image-wrapper {
        pointer-events: none;
        transform: none !important;

    } */

    .project-image-wrapper:hover {
        transform: none !important;
        box-shadow: none !important;
    }

    .project-title,
    .project-subtitle {
        transform: none !important;
        transition: none !important;
        /* Remove transition */
    }

    /* .project-title {
        font-size: 6vw;
    }

    .project-subtitle {
        font-size: 0.9rem;
    } */
}