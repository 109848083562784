.info-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    min-height: 10vh;
    width: 100vw;
    padding: 0;
    margin: 0;
    overflow: hidden;
    text-align: center;
}

.background-image {
    position: absolute;
    top: 1;
    left: 0;
    width: 100%;
    height: 105%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    overflow-y: visible;
    z-index: 0;
}

.services-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    position: relative;
    z-index: 1;
    /* Place it above background image */
}

.snap-section {
    opacity: 0;
    /* Start hidden */
    transform: translateY(75px);
    /* Start slightly below */
}

.text-content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 15vh 10vw;
    height: auto;
    width: 90%;
    z-index: 2;

}

.HeroTitle {
    margin-top: -25vh !important;
    text-align: justify;
    font-family: 'nyghtShift';
    font-size: 11rem;
    margin: 0;
    margin: 0 0 1rem 0;
}

.heroTitle-break {
    margin-bottom: -325px;
}

.HeroSubtitle {
    text-align: justify;
    font-size: 1.5rem;
    line-height: 1.5;
    margin: 0;
    max-width: 1000px;
}

.advocate-content {
    overflow: hidden;
    flex-direction: column;
    align-items: flex-start;
    justify-content: end;
    padding: 10vh 8vw 10vh 0vw;
    width: 50%;
    min-height: max-content;
    padding-bottom: 2rem;
    margin-left: auto;
    z-index: 2;

}

.advocateTitle {
    text-align: left;
    font-family: 'nyghtShift';
    font-size: clamp(3rem, 4.5rem, 6rem);
    margin: 0;
    margin-bottom: 1rem;
}

.advocateSubtitle {
    text-align: left;
    font-size: 1.5rem;
    line-height: 1.5;
    margin: 0;
    max-width: 800px;
}


br {
    margin-top: 0;
    margin-bottom: 0;
}

.line-container {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    margin-bottom: 3rem;
    z-index: 10;
    width: 100%;
}

.Service-attention-container .line-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 0rem;
    margin-bottom: 0rem;
    z-index: 10;
    width: 90%;
}

.break-line {
    width: 90%;
    height: 3px;
    border-radius: 20px;
    opacity: 0.9;
    background-color: #c52470;
}

.Service-attention-container {
    z-index: 20;
    display: flex;
    margin-left: 5%;
    align-items: center;
    justify-content: flex-end;
    width: 90%;
    flex-direction: row;
}

.service-title-container {
    color: white;
}

.service-title-container h1 {
    font-family: 'GothamBook';
    font-size: 5rem;
    margin-bottom: 40px;
}


#lbr {
    justify-content: end;
    padding-right: 10%;
}

#lbr2 {
    width: 90%;
}

.animated-text .char,
.animated-text .word {
    display: inline-block;
    opacity: 0;
    transform: translateY(50px);
}

.projects-section {
    width: 95%;
    text-align: start;
    z-index: 2;
    margin-top: 5rem;
    margin-bottom: 10rem;
    height: auto;
}

.projects-title {
    font-family: 'nyghtShift';
    font-size: 7rem;

    margin-bottom: 0rem;
    padding-left: 2.5%;
    padding-bottom: 2.5%;
}


.HeroTextSection {
    display: flex;
    text-align: center;
    z-index: 2;
    padding: 2rem 0;
    width: 90%;
    height: auto;
    box-sizing: border-box;
}

.HeroText {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: auto;
    width: 100%;
    z-index: 2;
    text-align: left;
    line-height: 1.15;
    margin: 0;
    letter-spacing: 0px;
    box-sizing: border-box;
}

.HeroText p {
    margin-top: 20px;
    font-size: 2rem;
}

.btn-primary {
    background-color: #c52470;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 9999px;
    text-transform: uppercase;
    font-family: var(--Gotham, sans-serif);
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.btn-primary:hover {
    background-color: rgba(197, 36, 112, 0.9);
}

.btn {
    position: relative;
    padding: clamp(0.8rem, 1.5vw, 2rem) clamp(1.5rem, 5vw, 6rem);
    /* Dynamically scale padding */
    font-size: clamp(1rem, 2.5vw, 2rem);
    /* Dynamically scale font-size */
    margin-top: clamp(1rem, 4vw, 5rem);
    /* Dynamically scale margin */
    right: clamp(10%, 33%);
}

/* Services Wrapper */
.services-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.message-development {
    text-align: center;
    margin-bottom: 2rem;
    font-size: 1.5rem;
    line-height: 1.6;
    color: #ccc;
    width: 90%;
    padding: 0 1rem;
    /* Add some padding for responsiveness */
}

.message-development strong {
    font-size: 2rem;
}

/* Services Container */
.services-container {
    display: flex;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    gap: clamp(0rem, 3vw, 8rem);
    width: 90%;
    justify-content: space-evenly;
}

/* Individual Service Item */
.service-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-radius: 10px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Service Icon */
.service-icon {
    width: 10vw;
    height: 10vw;
    max-width: 180px;
    /* Limit the max size */
    max-height: 180px;
    margin-bottom: 1rem;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.service-icon:hover {
    transform: translateY(-5px) scale(1.1);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

/* Service Title */
.service-title {
    font-size: 1.75rem;
    color: rgba(197, 36, 112, 1);

    /* uppercase text */
    text-transform: uppercase;
    margin: 0;
    line-height: 1.5;
    text-align: center;
}


_::-webkit-full-page-media,
_:future,
:root {

    .service-title-container h1 {
        z-index: 50;
        font-family: 'GothamBook';
        font-size: 5rem;
        height: 3rem;
        line-height: 1.2;
        display: block;
        visibility: visible;
    }
}

@media (min-width: 1024px) {
    .btn {
        position: relative;
        padding: 0.6vw 2vw;
        font-size: 1.2vw;
        margin-top: 10%;
        right: 33%;
    }
}

@media (max-width: 1024px) {
    .services-container {
        grid-template-columns: repeat(3, 1fr);
        /* Switch to 3 items in a row */
    }
}

/* Responsive styling for iPad/tablet screens */
@media (min-width: 768px) and (max-width: 1024px) {

    .HeroTitle {
        font-size: 6rem;
        margin-top: 0;
        text-align: center;
        line-height: 1.2;
        padding-left: 5%;
    }

    .HeroSubtitle {
        font-size: 1.25rem;
        line-height: 1.4;
        text-align: center;
        max-width: 90%;
        margin: 1rem auto;
    }

    .advocate-content {
        width: 80%;
        margin: 0 auto;
        padding: 0rem;
        margin-bottom: 5rem;
        text-align: center;
    }

    .advocateTitle {
        font-size: 3rem;
        text-align: center;
        margin-bottom: 1rem;
    }

    .advocateSubtitle {
        font-size: 1.25rem;
        line-height: 1.5;
        text-align: center;
    }

    .line-container {
        margin: 2rem 0;
    }

    .break-line {
        width: 85%;
        height: 2px;
    }

    .projects-section {
        width: 100%;
        margin-bottom: 3rem;
        text-align: center;
    }

    .projects-title {
        font-size: 4rem;
        margin-bottom: 1rem;
        padding: 0;
    }

    .HeroTextSection {
        width: 90%;
        padding: 1rem 2rem;
        min-height: 50vh;
    }

    .HeroText p {
        text-align: left;
        margin: 0 auto;
        letter-spacing: 1px;
        font-size: 1.5rem;
        line-height: 1.75;
        word-spacing: 0.05em;
    }

    .Service-attention-container {
        flex-direction: column;
        align-items: center;
        text-align: center;
        justify-content: center;
        margin-left: 0;
        width: 100%;
    }

    .Service-attention-container .line-container {
        margin-top: 1rem;
        margin-bottom: 1rem;
        justify-content: center;
    }

    .service-title-container h1 {
        font-size: 3rem;
        margin-top: 0rem;
        margin-bottom: 1rem;
    }

    .btn {
        padding: 1rem 2rem;
        font-size: 1rem;
        margin-top: 1.5rem;
        width: auto;
        right: 0;
        text-align: center;
    }

    .btn-primary {
        padding: 0.8rem 1.5rem;
        font-size: 0.9rem;
        margin-top: 3rem;
    }

    .service-icon {
        width: 150px;
        height: 150px;
    }

    .service-title {
        font-size: 1.75rem;
    }
}

@media (max-width: 768px) {

    _::-webkit-full-page-media,
    _:future,
    :root {

        .service-title-container {
            padding-left: 0%;
        }

        .Service-attention-container .line-container {
            display: none;
        }

        .service-title-container h1 {
            font-size: 4rem;
        }

        .HeroText {
            padding-top: 2rem;
            height: -webkit-max-content;
        }

    }

    .message-development {
        font-size: 1.25rem;
    }

    .services-container {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        /* Adjust to 2 items in a row */
        justify-content: center;
        /* Center rows with fewer items */
    }

    .service-icon {
        width: 100px;
        height: 100px;
    }

    .service-title {
        font-size: 1.5rem;
    }

    /* General container */

    /* Background image adjustments */
    .background-image {
        background-position: center top;
        background-size: cover;
    }

    /* Hero title adjustments */
    .HeroTitle {
        font-size: 3rem;
        margin-top: 0;
        text-align: center;
        line-height: 1.2;
        padding-left: 10%;
        word-spacing: normal;
    }

    .heroTitle-break {
        margin-bottom: -50px;
    }

    /* Hero subtitle adjustments */
    .HeroSubtitle {
        font-size: 1rem;
        line-height: 1.4;
        text-align: center;
        max-width: 90%;
        margin: 1rem auto;
    }

    /* Text content adjustments */
    .text-content {
        padding: 2rem 1rem;
        text-align: center;
    }

    /* Advocate content adjustments */
    .advocate-content {
        width: 90%;
        margin: 0 auto;
        padding: 0rem;
        margin-bottom: 7rem;
        text-align: center;
    }

    .advocateTitle {
        font-size: 2.5rem;
        text-align: center;
        margin-bottom: 1rem;
    }

    .advocateSubtitle {
        font-size: 1rem;
        line-height: 1.5;
        text-align: center;
    }

    /* Line container adjustments */
    .line-container {
        margin: 1.5rem 0;
    }

    .break-line {
        width: 80%;
        height: 2px;
    }

    #lbr {
        justify-content: end;
        padding-right: 10%;
    }

    #lbr2 {
        width: 90%;
    }

    /* Projects section adjustments */
    .projects-section {
        width: 100%;
        margin-bottom: 2rem;
        text-align: center;
    }

    .projects-title {
        font-size: 2.5rem;
        margin-bottom: 1rem;
        padding: 0;
    }

    /* Hero text section adjustments */
    .HeroTextSection {
        width: 90%;
        padding: 0rem 1rem;
        min-height: 50vh;
    }

    .HeroText p {
        text-align: left;
        margin: 0 auto;
        letter-spacing: 1px;
        font-size: 1.25rem;
        line-height: 1.75;
        word-spacing: 0.05em;
    }



    /* Service attention container adjustments */
    .Service-attention-container {
        flex-direction: column;
        align-items: center;
        text-align: center;
        justify-content: center;
        margin-left: 0;
        width: 100%;
    }

    .Service-attention-container .line-container {
        margin-top: 1rem;
        margin-bottom: 1rem;
        justify-content: center;
    }

    .service-title-container h1 {
        font-size: 2rem;
        margin-top: 0rem;
        margin-bottom: 1rem;
    }

    /* Button adjustments */
    .btn {
        padding: 1rem 2rem;
        font-size: 1rem;
        margin-top: 1.5rem;
        width: auto;
        right: 0;
        text-align: center;
    }

    .btn-primary {
        padding: 0.8rem 1.5rem;
        font-size: 0.9rem;
        margin-top: 3rem;
    }

    .service-icon {
        width: 150px;
        height: 150px;
    }

    .service-title {
        font-size: 1.75rem;
    }


}