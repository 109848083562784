/* src/components/sections/IntroVideo.css */
.IntroVideo {
    position: relative;
    background-color: #000;
    z-index: 1000;
    height: 100vh;
}

.noScroll {
    height: 100vh;
    overflow: hidden;
}

.video-container {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
}


.video {
    width: 100%;
    height: auto;
    object-fit: contain;
}

@media (max-width: 768px) {
    .video-container {
        height: 100vh;
    }
}

/* Styles for tablets */
@media (min-width: 768px) and (max-width: 1024px) {
    .IntroVideo {
        height: 80vh;
        /* Adjust height for tablets */
    }

    .video-container {
        height: 80vh;
        /* Adjust height for tablets */
    }

    .video {
        width: 100%;
        height: auto;
        object-fit: cover;
        /* Ensure the video covers the container */
    }
}