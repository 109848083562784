/* Hide scrollbar for all elements */
::-webkit-scrollbar {
    display: none;
    /* For Chrome, Safari, and Edge */
}

html,
body {
    -ms-overflow-style: none;
    /* For Internet Explorer and Edge */
    scrollbar-width: none;
    /* For Firefox */
    background-color: black;
}

#smooth-wrapper {
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;
    background-image: url('../assets/DoddBG1.jpg');
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
}

#smooth-content {
    will-change: transform;
}

.sticky-header {
    position: fixed;
    /* Fix the header to the top */
    top: 0;
    left: 0;
    width: 100%;
    /* Full width */
    z-index: 1000;
    /* Ensure it stays on top of other content */
    background-color: rgba(0, 0, 0, 0.9);

}

.Main {
    min-height: 100vh;
    overflow-x: hidden;
    /* Prevent horizontal scrolling */
    overflow-y: visible;
    /* Let the wrapper handle snapping */
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    color: #ffffff;
}

.wrapper {
    max-width: 100vw;
    margin: 0 0;
    overflow: hidden;
    letter-spacing: 1px;
    height: auto;
    padding: 0rem 0rem;
}

.overlay {
    position: absolute;
    /* Constrained to the wrapper */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    /* Black overlay */
    z-index: 500;
    /* Ensure it covers the wrapper content */
    opacity: 1;
    visibility: visible;
    transition: opacity 0.5s ease, visibility 0.5s ease;
}

.overlay.hidden {
    opacity: 0;
    visibility: hidden;
}

.contact-component-container {
    margin-top: -5px;
    margin-bottom: -5px;
}

@media (max-width: 768px) {
    #smooth-wrapper {
        width: 100%;
        height: auto;
        overflow: hidden;
        background-image: url('../assets/mobile/home-mobi.png');
        background-size: cover;
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
    }

    body {
        overflow: auto !important;
        height: auto !important;
    }

    .contact-component-container {
        margin-top: -5px;
        margin-bottom: -5px;
    }
}