.GetInTouch {
    background-color: transparent;
    padding: 5rem 0rem;
    color: white;
    text-align: center;
    font-family: 'GothamBook';
    will-change: transform;
    border: none;
    margin-top: -5px;
    margin-bottom: -5px;
    outline: none;
    box-shadow: none;
}

#contact {
    will-change: transform;
}


.GetInTouch .content-cantact {
    position: relative;
    z-index: 2;
    /* Keeps the text above the overlay */
}

.line-contact {
    background-color: transparent;
    display: flex;
    justify-content: center;
    width: 100%;
    padding-bottom: 100px;
}

.contact-component-container .break-contact {
    margin-top: 5px;
    width: 90%;
    height: 3px;
    border-radius: 20px;
    background-color: #c52470;
}

.contact-container {
    display: flex;
    flex-direction: row;
    /* Horizontal layout for larger screens */
    align-items: center;
    justify-content: space-between;
    height: 100px;
    width: 90%;
    padding-left: 5%;
    padding-right: 5%;
    text-align: left;
}

.contact-title {
    position: relative;
    margin-top: 0;
    margin-bottom: 0px;
    font-size: 3rem;
    margin-bottom: 20px;
    flex: 1;
    /* Allow title to take space */
    text-align: left;
    /* Ensure left alignment */
}

.contact-info {
    display: flex;
    justify-content: flex-end;
    /* Push the email to the right */
    font-size: 2rem;
    flex: 1;
    /* Allow email section to take space */
}

.contact-email {
    height: 20px;
    font-size: 1.5rem;
    color: white;
    text-decoration: none;
    margin-left: auto;
}

.contact-email:hover {
    text-decoration: underline;
}

.contact-email:hover {
    text-decoration: underline;
}

_::-webkit-full-page-media,
_:future,
:root {
    .content-cantact {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: auto;
        padding-top: 3rem;
        /* Adjust for Safari rendering issue */
    }

    .contact-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        width: 100%;
        max-width: 90%;
        margin: 0 auto;
    }

    .contact-title {
        font-size: 4rem;
        margin: 0;
        padding-bottom: 1rem;
        text-align: left;
        height: 3rem;
        line-height: 1.2;
    }

    .contact-info {
        line-height: 1.2;
        font-size: 1.8rem;
        height: 3rem;
        margin-top: 1rem;
        text-align: center;
    }

    .contact-email:hover {
        text-decoration: underline;
    }

}



@media (max-width: 768px) {

    _::-webkit-full-page-media,
    _:future,
    :root {
        .contact-title {
            line-height: 1;
            font-size: 2rem;
        }

        .contact-email {
            font-size: 1.25rem;
        }


        .GetInTouch {
            padding-top: 0rem;
        }

        .contact-container {

            flex-direction: column;
            gap: 0%;
        }

        .contact-info {
            padding-top: 20px;
            font-size: 1.2rem;
        }
    }

    .GetInTouch {
        padding: 1rem;
    }

    .content-cantact {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
        border: none;
    }

    .contact-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 90%;
        padding: 1rem;
        margin: 0 auto;
    }

    .contact-title {
        padding-bottom: 1rem;
        text-align: center;
        margin: 0;
        height: auto;
        line-height: 4rem;
    }

    .contact-info {
        text-align: center;
        font-size: 1.2rem;
        margin-top: 1rem;
    }

    .contact-email {
        color: white;
        text-decoration: none;
        height: 1rem;
        margin: 1rem;
        font-size: 1.25rem;
    }

    .contact-email a {
        height: 1rem !important;
    }

    .contact-email:hover {
        text-decoration: underline;
    }
}