@supports (hanging-punctuation: first) and (font: -apple-system-body) and (-webkit-appearance: none) {
  html {
    overscroll-behavior: auto !important;
  }
}

@font-face {
  font-family: 'GothamBook';
  src: url('./assets/fonts/metropolis/Metropolis-Light.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  /* For smoother font rendering */
  font-display: swap;
  /* Improves rendering performance */

}

@font-face {
  font-family: 'nyghtShift';
  src: url('./assets/fonts/nyght/TTF/NyghtSerif-Medium.ttf') format('truetype');
  font-weight: 800;
}

@font-face {
  font-family: 'NSI';
  src: url('./assets/fonts/nyght/TTF/NyghtSerif-RegularItalic.ttf') format('truetype');
  font-weight: 100;
}

@font-face {
  font-family: 'NSMI';
  src: url('./assets/fonts/nyght/TTF/NyghtSerif-MediumItalic.ttf') format('truetype');
  font-weight: 800;
}


body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}