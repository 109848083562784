/* Header.css */

.header {
    position: fixed;
    width: 100%;
    z-index: 1900;
    background-color: rgba(3, 3, 4, 0.6);
    pointer-events: auto;
}

.header .container {
    width: 100%;
    max-width: 1713px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 5rem;
    box-sizing: border-box;
}

.logo {
    margin-right: auto;
}

.logo-image {
    width: 8vw;
}

.menu-toggle {
    display: none;
    background: none;
    border: none;
    cursor: pointer;
}

.menu-icon {
    width: 2.5rem;
    height: 2rem;
}

.nav {
    display: flex;
    align-items: center;
}

.close-menu {
    display: none;
    position: absolute;
    top: 1rem;
    right: 1.5rem;
    background: none;
    border: none;
    cursor: pointer;
}

.close-icon {
    color: white;
    width: 2rem;
    height: 2rem;
}

.nav-list {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    z-index: 2000;
}

.nav-list li {
    margin-left: 2.8rem;
}

.nav-link {
    font-family: 'GothamBook', Arial;
    height: 30px;
    cursor: pointer;
    z-index: 1000;
    text-transform: uppercase;
    text-decoration: none;
    color: white;
    background-color: transparent;
    border-radius: none;
    border: none;
    font-size: 1rem;
    transition: opacity 0.3s;
    pointer-events: auto !important;
}

.nav-link:hover {
    color: #c52470;
    opacity: 0.6;
}

.nav-link-disabled {
    opacity: 0.6;
    pointer-events: none;
}

@supports (hanging-punctuation: first) and (font: -apple-system-body) and (-webkit-appearance: none) {
    .nav-list {
        display: -webkit-flex;
    }


}

/* Responsive Menu for smaller screens */
@media (max-width: 1024px) {

    .header {
        height: auto;
    }

    .header .container {
        padding: 1.5rem 1.5rem;
        padding-right: 0rem;
        width: 100%;
    }

    .logo {
        scale: 4;
        margin-right: 60%;
    }




    .menu-toggle {
        position: relative;
        display: block;
    }

    .nav {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #c52470;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        display: none;
    }

    .nav.open {
        display: flex;
    }

    .close-menu {
        display: block;
    }

    .nav-list {
        text-align: center;
        flex-direction: column;
    }

    .nav-list li {
        margin: 1rem 0;
    }

    .nav-link {
        letter-spacing: 1px;
        font-size: 2.5rem;
    }
}

/* Responsive Menu for iPad/tablet screens */
@media (min-width: 768px) and (max-width: 1024px) {
    .header {
        height: auto;
    }

    .header .container {
        padding: 1.5rem 2rem;
        width: 100%;
    }

    .logo {
        scale: 3;
        margin-right: 50%;
    }

    .menu-toggle {
        position: relative;
        display: block;
    }

    .nav {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #c52470;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        display: none;
    }

    .nav.open {
        display: flex;
    }

    .close-menu {
        display: block;
    }

    .nav-list {
        text-align: center;
        flex-direction: column;
    }

    .nav-list li {
        margin: 1rem 0;
    }

    .nav-link {
        letter-spacing: 1px;
        font-size: 2rem;
    }
}